<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 10px -10px 10px -10px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 20%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 20%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="状态" prop="finishsign" style="width: 15%">
              <a-select v-model="queryParams.finishsign" >
                <a-select-option value="">全部</a-select-option>
                <a-select-option :value="0">处理中</a-select-option>
                <a-select-option :value="1">已完成</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="巡查完成情况" prop="isfinished" style="width: 20%">
              <a-select v-model="queryParams.isfinished">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :value="0">及时完成巡查</a-select-option>
                <a-select-option :value="1">未及时完成巡查</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button type="primary" @click="showModal()"><a-icon type="plus"></a-icon>新建</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
<!--          <a slot="inspectionshift" slot-scope="text,record" @click="showModal(record)">{{ text }}</a>-->
          <span slot="patroldate" slot-scope="value, record">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <a @click="showModal(record)">{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</a>
            </a-tooltip>
          </span>
          <span slot="finishsign" slot-scope="value">
            <a-tag :color="value=='1'?'green':value=='0'?'red':''">{{value=='1'?'已完成':value=='0'?'处理中':value}}</a-tag>
          </span>
          <span slot="isfinished" slot-scope="value">
            <a-tag :color="value==0?'green':'red'">{{value==0?'及时完成巡查':'未及时完成巡查'}}</a-tag>
          </span>
          <span slot="patrolshift" slot-scope="value,record">
            <span>{{patrolShifts[record.patrolshift-1].value}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
<!--                <a-menu-item :key="'add-'+record.patrolrecordid">新增</a-menu-item>-->
                <a-menu-item :key="'detail-'+record.patrolrecordid">详情</a-menu-item>
<!--                <a-menu-item :key="'edit-'+record.patrolrecordid">修改</a-menu-item>-->
<!--                <a-menu-item :key="'delete-'+record.patrolrecordid">修改</a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </page-container>
</template>
<script>
import { getItemFromArrayByKey, getTimeRange } from 'U'
import { mapState } from 'vuex'
import moment from 'moment'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import {
  deletePatrolRecord,
  getPatrolRecordListByCondition,
} from "A/patrol";
import {patrolShifts} from "@/json/wlwhistory";
export default {
  name: 'patrolRecord',
  mixins: [deptselect,pagination],
  props: ['pageType'],
  data() {
    return {
      moment,
      showAdvanced: false,
      patrolShifts,
      queryParams: {
        monitorpointname:'',
        starttime:'',
        endtime:'',
        finishsign:null,
        isfinished:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns: [
        {
          title: '巡查记录时间',
          dataIndex: 'patroldate',
          key: 'patroldate',
          ellipsis: true,
          scopedSlots: { customRender: 'patroldate' },
        },
        {
          title: '班次',
          dataIndex: 'patrolshift',
          key: 'patrolshift',
          ellipsis: true,
          scopedSlots: { customRender: 'patrolshift' },
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'finishsign',
          key: 'finishsign',
          ellipsis: true,
          width: 80,
          scopedSlots: { customRender: 'finishsign' }
        },
        {
          title: '巡查情况',
          dataIndex: 'isfinished',
          key: 'isfinished',
          width: 110,
          scopedSlots: { customRender: 'isfinished' }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '系统巡查';
    },
    breadcrumb() {
      return [
        {
          name: '工作查阅与报告',
          path: ''
        },
        {
          name: '工作查阅与报告',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
      this.initDeptOptionsAll();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.queryParams.finishsign=null
      this.queryParams.isfinished=""
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getPatrolRecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    showModal(record) {
      this.$router.push({
        name: 'addOrEditPatrolsRecord',
        params: {
          monitorpointnum: record.monitorpointnum,
          patrolrecordid: record.patrolrecordid,
          patrolshift: record.patrolshift,
          modalTitle:"巡查记录查询"
        }
      })
      // this.$router.push({
      //   name: 'addOrEditPatrolsRecord',
      //   params: {
      //     patrolrecordid: "",
      //   }
      // })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let patrolrecordid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'patrolrecordid', patrolrecordid);
      if(type == 'detail') {
        this.$router.push({
          name: 'addOrEditPatrolsRecord',
          params: {
            monitorpointnum: record.monitorpointnum,
            patrolrecordid: record.patrolrecordid,
            patrolshift: record.patrolshift,
            modalTitle:"巡查记录查询"
          }
        })
      }else if(type == 'edit') {
        this.$router.push({
          name: 'addOrEditPatrolsRecord',
          params: {
            monitorpointnum: record.monitorpointnum,
            patrolrecordid: record.patrolrecordid,
            patrolshift: record.patrolshift,
            modalTitle:"巡查记录查询"
          }
        })
      }else if(type == 'add'){
        this.$router.push({
          name: 'addOrEditPatrolsRecord',
          params: {
            monitorpointnum: record.monitorpointnum,
            patrolrecordid: "",
          }
        })
      }else if(type == 'delete'){
        this.deleteConfirm(patrolrecordid, record)
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.patrolrecordid);
      }).catch(()=>{
      });
    },
    delete(patrolrecordid) {
      this.showLoading();
      if(patrolrecordid) {
        let params = {
          patrolrecordid
        };
        deletePatrolRecord(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    Rowclick(record, index) {
      return {
        on: {
          click: () => {
            this.$router.push({
              name: 'addOrEditPatrolsRecord',
              params: {
                monitorpointnum: record.monitorpointnum,
                patrolrecordid: record.patrolrecordid,
                patrolshift: record.patrolshift,
                modalTitle:"巡查记录查询"
              }
            })
          },
          // dblclick: () => {
          //   this.$router.push({
          //     name: 'addOrEditPatrolsRecord',
          //     params: {
          //       monitorpointid: record.monitorpointid,
          //     }
          //   })
          // }
        }
      };
    }
  }
}
</script>
<style lang="scss" scoped>
</style>